import React from "react";
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Dialog,
  IconButton,
  Theme,
  createStyles,
  WithStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

interface PhotographyState {
  galleryOpen: boolean;
  galleryLink: string;
  title: string;
}

const myPhotos = [
  {
    title: "Turkey 2015",
    category: "travel photography",
    description: "A five week roadtrip around Turkey.",
    thumbnail: "./static-assets/photography/gallery-thumb_turkey15_4x5.jpg",
    galleryLink: "https://lisahadinger.at/Turkey_2015.php",
  },
  {
    title: "Emir",
    category: "portrait  •  studio photography",
    description:
      "A series of portraits I did over the years for my friend Emir. He is a hobby tattoo artist based in Vienna.",
    thumbnail: "./static-assets/photography/gallery-thumb_emir.jpg",
    galleryLink: "https://lisahadinger.at/Emir.php",
  },
  {
    title: "Andrea",
    category: "portrait  •  studio photography",
    description:
      "A portrait session for my friend Andrea Seemayer, who is a classical singer.",
    thumbnail: "./static-assets/photography/gallery-thumb_andrea.jpg",
    galleryLink: "https://lisahadinger.at/Andrea.php",
  },
  {
    title: "Baby on Board",
    category: "studio photography",
    description: "A pregnancy shooting for friends of mine.",
    thumbnail: "./static-assets/photography/gallery-thumb_babyonboard.jpg",
    galleryLink: "https://lisahadinger.at/baby_on_board.php",
  },
];

const albumStyles = (theme: Theme) =>
  createStyles({
    clickArea: {
      minHeight: 290,
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
    },
  });

interface Props extends WithStyles<typeof albumStyles> {
  album: any;
  openGallery: any;
}

const Album: any = (props: Props) => {
  const { classes, album, openGallery } = props;
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
      <Card>
        <CardActionArea
          className={classes.clickArea}
          onClick={() => openGallery()}
        >
          <CardMedia
            style={{ height: 140 }}
            src={album.thumbnail}
            component="img"
            title={album.title}
          />
          <CardContent>
            <Typography variant="h6" component="h2">
              {album.title}
            </Typography>
            <Typography
              gutterBottom
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {album.category}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {album.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const StyledAlbum = withStyles(albumStyles)(Album);

class Photography extends React.Component<{}, PhotographyState> {
  constructor(props: any) {
    super(props);
    this.state = {
      galleryOpen: false,
      galleryLink: "",
      title: "",
    };
  }

  render() {
    return (
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography component="h1" variant="h2">
            Photography
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" align="left" paragraph>
            Photography is one of my hobbies, and I mostly take pictures while
            travelling.
            <br />
            Occasionally, I also do portraits - I have a handful of preferred
            models that I really enjoy taking pictures of.
          </Typography>
        </Grid>
        <Grid item>
          <Grid container direction="row" spacing={4}>
            {myPhotos.map((album, i) => {
              return (
                <StyledAlbum
                  key={`album-${i}`}
                  album={album}
                  openGallery={() =>
                    this.setState({
                      galleryOpen: true,
                      galleryLink: album.galleryLink,
                      title: album.title,
                    })
                  }
                />
              );
            })}
          </Grid>
        </Grid>

        <Dialog
          open={this.state.galleryOpen}
          onClose={() =>
            this.setState({ galleryOpen: false, galleryLink: "", title: "" })
          }
          fullScreen={false}
          fullWidth={true}
          maxWidth="lg"
        >
          <IconButton
            onClick={() =>
              this.setState({ galleryOpen: false, galleryLink: "", title: "" })
            }
            style={{ position: "absolute", top: 8, right: 8 }}
          >
            <Close />
          </IconButton>
          {this.state.galleryLink.length && (
            <iframe
              title={this.state.title}
              src={this.state.galleryLink}
              style={{ height: "calc(100vh - 32px)", border: "none" }}
            />
          )}
        </Dialog>
      </Grid>
    );
  }
}

export const Component = Photography;
export default Photography;
