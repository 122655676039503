import React from "react";
import { Grid, Typography, Collapse } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";

interface ProjectsState {
  showBakk1Abstract: boolean;
  showBakk2Abstract: boolean;
  showDaAbstract: boolean;
}

class Projects extends React.Component<{}, ProjectsState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showBakk1Abstract: false,
      showBakk2Abstract: false,
      showDaAbstract: false,
    };
  }

  componentDidMount() {
    this.setState({ showBakk2Abstract: false });
  }

  render() {
    return (
      <Grid container direction="column" spacing={4}>
        <Grid item>
          <Typography component="h1" variant="h2">
            Papers &amp; Side Projects
          </Typography>
        </Grid>

        <Grid item>
          <Typography component="h2" align="left" variant="h4" gutterBottom>
            Papers
          </Typography>

          <Typography component="h3" variant="body1" align="left">
            <a
              href="https://www.lisahadinger.at/download/mt141028_Hadinger-Lisa_bachelor-thesis-2_2016_17.pdf"
              target="_blank"
              rel="noreferrer"
            >
              "Game-based learning and its applicability in digital learning
              environments"
            </a>
          </Typography>
          <Typography
            variant="subtitle2"
            align="left"
            color="textSecondary"
            gutterBottom
          >
            Second bachelor thesis | University of Applied Sciences, St. Pölten
            / Austria
          </Typography>
          <Typography variant="body1" align="left" paragraph>
            In short, I developed a learning game prototype to test selected
            principles of game-based learning in the context of teaching
            chemistry basics to high school students.
            <br />
            Read the paper's abstract{" "}
            <span
              className="link"
              onClick={() =>
                this.setState({
                  showBakk2Abstract: this.state.showBakk2Abstract
                    ? false
                    : true,
                })
              }
            >
              here
            </span>
            .
          </Typography>
          <Collapse
            in={this.state.showBakk2Abstract}
            style={{ paddingLeft: 40 }}
          >
            <Typography variant="body2" align="left" paragraph>
              Game-based learning, even though not being a new concept, is
              becoming increasingly popular within the learning sector. Also,
              elements like storytelling and emotions become more and more
              important in the design of such games. Research substantiates
              these trends. In order to investigate the effect of digital
              game-based learning (DGBL) a prototype for acquiring basic
              chemistry knowledge was developed. An empirical study was
              conducted in order to find out whether the prototype was able to
              increase interest and relatability, and improve knowledge and
              perceived difficulty of the subject. The target group was students
              in their first year of high school, who hadn’t had a lot of formal
              education in chemistry yet, and whose interest in chemistry was
              rather low on average. It was found that the prototype improved
              their interest in the subject. The participants perceived
              chemistry as less difficult and their own knowledge as better than
              before the test. Also, they found chemistry to be more relatable
              to their everyday lives. They also considered the frame story to
              be very helpful and motivating in understanding the learning
              objectives. In order to fully understand the prototype’s effects,
              more research should be carried out with different age groups from
              different social and educational backgrounds, different settings,
              and also with more advanced versions of the game.
            </Typography>
          </Collapse>

          <Typography component="h3" variant="body1" align="left">
            <a
              href="https://www.lisahadinger.at/download/mt141028_Hadinger-Lisa_bachelor-thesis-1_2015_16.pdf"
              target="_blank"
              rel="noreferrer"
            >
              "Introducing programming through gamification"
            </a>
          </Typography>
          <Typography
            variant="subtitle2"
            align="left"
            color="textSecondary"
            gutterBottom
          >
            First bachelor thesis | University of Applied Sciences, St. Pölten /
            Austria
          </Typography>
          <Typography variant="body1" align="left" paragraph>
            This is a research paper giving an overview of various platforms
            that teach programming for beginners using various methods of
            gamification.
            <br />
            Read the paper's abstract{" "}
            <span
              className="link"
              onClick={() =>
                this.setState({
                  showBakk1Abstract: this.state.showBakk1Abstract
                    ? false
                    : true,
                })
              }
            >
              here
            </span>
            .
          </Typography>
          <Collapse
            in={this.state.showBakk1Abstract}
            style={{ paddingLeft: 40 }}
          >
            <Typography variant="body2" align="left" paragraph>
              Computing skills have increasingly gained importance as the
              Information and Communications Technologies (ICT) sector has grown
              rapidly and will continue to do so. However, experts expect a
              shortage in skilled workers as few people consider learning to
              program an option regarding their education and career. Moreover,
              women and socially disadvantaged groups are still dramatically
              underrepresented in the ICT sector. In order to make learning to
              program more entertaining, less strenuous and available to a broad
              and diverse majority, a considerable number of initiatives now use
              gamification. It was found that in general challenges and
              showcases are the most frequently used game elements when teaching
              programming. However, this strongly depends on the target group,
              as children are more often presented with leaderboards, showcases
              and challenges while university students encounter badges more
              often in addition to leaderboards and showcases. Programs
              targeting girls and women rely more on role models and tutoring
              instead of gamification. In order to improve gamified learning
              experiences, player types and learning styles should be taken into
              account. In addition to that, the curriculum should constantly be
              revised and evaluated to identify topics and concepts learners
              struggle most with.
            </Typography>
          </Collapse>

          <Typography component="h3" variant="body1" align="left">
            <a
              href="https://www.lisahadinger.at/download/Diplomarbeit_Hadinger_Lisa_Ortsnamen_in_Suedboehmen.pdf"
              target="_blank"
              rel="noreferrer"
            >
              "Deutsche und tschechische Ortsnamen in Südböhmen"
            </a>
          </Typography>
          <Typography
            variant="subtitle2"
            align="left"
            color="textSecondary"
            gutterBottom
          >
            Master thesis | University of Vienna / Austria
          </Typography>
          <Typography variant="body1" align="left" paragraph>
            This thesis shows how today's place name landscape in Southern
            Bohemia came about, what source language the individual toponyms
            come from and how they have changed over time. Particular attention
            was paid to the changes in the place name landscape after the
            disappearance of the German-speaking population after 1945.
            <br />
            Read the paper's abstract{" "}
            <span
              className="link"
              onClick={() =>
                this.setState({
                  showDaAbstract: this.state.showDaAbstract ? false : true,
                })
              }
            >
              here
            </span>{" "}
            (in German).
          </Typography>
          <Collapse in={this.state.showDaAbstract} style={{ paddingLeft: 40 }}>
            <Typography variant="body2" align="left" paragraph>
              Diese Arbeit beschäftigt sich mit den tschechischen und deutschen
              Ortsnamen in den ehemaligen deutsch besiedelten Gebieten der
              Gemeindebezirke Kaplice (Kaplitz) und Trhové Sviny (Schweinitz) in
              Südböhmen, ihren Beziehungen zueinander, und den Verfahren, die
              auf die deutschen Toponyme nach der Vertreibung und Aussiedelung
              der deutschsprachigen Bevölkerung angewendet wurden. Bestehende
              Forschungsergebnisse und Klassifizierungen der
              Umbenennungsmethoden wurden miteinander verglichen und auf den in
              dieser Arbeit behandelten geographischen Bereich angewandt sowie
              durch eigene Untersuchungen ergänzt. Ebenso wurde die
              tschechisch-deutsche Lautgeschichte berücksichtigt, um
              Zusammenhänge zwischen den jeweiligen Ortsnamen zu erkennen. Die
              Auswertung der Ergebnisse ergab eine weitestgehend schonende
              Praktik der Bohemisierung der ehemals deutsch besiedelten Gebiete.
              Insgesamt konnten nur vier Neubenennungen nachgewiesen werden; die
              restlichen Veränderungen nach 1945 waren hauptsächlich
              Modifizierungen bereits bestehender tschechischer Ortsnamen sowie
              Übersetzungen deutscher Ortsnamen.
            </Typography>
          </Collapse>
        </Grid>

        <Grid item>
          <Typography component="h2" align="left" variant="h4" gutterBottom>
            Side and Student Projects
          </Typography>

          <Grid container direction="column" wrap="nowrap" spacing={8}>
            <Grid item>
              <Typography component="h3" variant="h6" align="left">
                Ad Libitum
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
                color="textSecondary"
                paragraph
              >
                Bachelor project in year three at the University of Applied
                Sciences / St. Pölten, Austria
              </Typography>
              <Grid container direction="row" spacing={5}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" align="left" gutterBottom>
                    Our bachelor project is a single player open world adventure
                    game made with{" "}
                    <a
                      href="https://unity3d.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Unity3D
                    </a>
                    . The player's goal is to find their way through an island
                    world which is divided between those who fight for their
                    free will and those who are content with Fate deciding for
                    them.
                  </Typography>
                  <Typography variant="body1" align="left">
                    Each quest demands a choice whether the player will solve it
                    in favor of Fate, who rules as a god over the island, or in
                    favor of the rebels, who resist Fate. Each choice affects
                    the world and the story while the player is faced with
                    peculiar characters, moral dilemmas, monsters, spirits and
                    an ultimate fight.{" "}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                    className="thumbnail"
                    src="./static-assets/adlibitum_logo_poster.png"
                    alt="Logo Ad Libitum - a flame of bright blue fire on a dark background"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                    className="thumbnail"
                    src="./static-assets/adlibitum_poster.png"
                    alt="A poster showing various in-game characters"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" align="left" gutterBottom>
                    Travel to Hopena Island and explore as Mani. Meet the
                    inhabitants of the island. There is no good, no evil, no
                    right, no wrong. There is just your choice. And the
                    consequences.
                    <br />
                    Can you face them?{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography component="h3" variant="h6" align="left">
                The Color of Sound
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
                color="textSecondary"
                paragraph
              >
                Multimedia project in year two at the University of Applied
                Sciences / St. Pölten, Austria
              </Typography>
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" align="left" paragraph>
                    A project I did for my Interactive Media class. This sketch
                    done with{" "}
                    <a
                      href="http://www.processing.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Processing
                    </a>{" "}
                    basically takes your laptop microphone's input and runs a
                    frequency analysis, mapping amplitude to brightness and
                    frequency to color hue.
                    <br />
                    Thus, you get colorful visuals which vary depending on that
                    kind of sound you feed it - different styles of music,
                    noise, different voices talking, etc.
                    <br />
                    There are three visual modes: Frequency spectrum, Bubbles
                    and Cubes - all of them move according to the input.
                    <br /> Enjoy!
                  </Typography>

                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <GetApp color="secondary" />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="left" gutterBottom>
                        <a
                          href="https://www.lisahadinger.at/download/color-of-sound_windows64.zip"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download for Windows
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <GetApp color="secondary" />
                    </Grid>
                    <Grid item>
                      <Typography variant="body1" align="left" gutterBottom>
                        <a
                          href="https://www.lisahadinger.at/download/color-of-sound_macosx.zip"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Download for Mac
                        </a>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <img
                    className="thumbnail"
                    src="./static-assets/screenshot_color-of-sound_bubbles.png"
                    alt="Screenshot of the project's user interface"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography component="h3" variant="h6" align="left">
                BZH (Bilder zum Hören)
              </Typography>
              <Typography
                variant="subtitle2"
                align="left"
                color="textSecondary"
                paragraph
              >
                Multimedia project in year one at the University of Applied
                Sciences / St. Pölten, Austria
              </Typography>
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" align="left" gutterBottom>
                    A video project I did with four of my university colleagues
                    themed 'The Sound of Images'.
                    <br /> Our interpretation was to remix a song, program
                    audio-reactive visuals using{" "}
                    <a
                      href="http://www.processing.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Processing
                    </a>{" "}
                    and Quartz Composer, project our visuals onto stage smoke
                    and circus artist Anna Golovko, and build a music video from
                    the footage.
                  </Typography>
                  <Typography variant="body1" align="left" gutterBottom>
                    <a
                      href="https://youtu.be/ZvvxTxv55mc"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Watch it on YouTube!
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <img
                    className="thumbnail"
                    src="./static-assets/screenshot_bzh.png"
                    alt="Still of the video's intro frame showing the letters BZH"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export const Component = Projects;
export default Projects;
