import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'
const AdventPro = {
  fontFamily: '"Advent Pro"',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: 'url(https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;600;700)',
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const OpenSans = {
  fontFamily: '"Open Sans"',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: 'url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600)',
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1F6FC6'
    },
    secondary: {
      main: '#F9640E'
    },
    overrides: {
      
    }
  },
  typography: {
    fontFamily: [
      '"Open Sans"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [AdventPro, OpenSans],
        },
      },
    },
    h1: {
      fontFamily: ['"Advent Pro"', '"Open Sans"', '"Helvetica Neue"']
    },
    h2: {
      fontFamily: ['"Advent Pro"', '"Open Sans"', '"Helvetica Neue"']
    },
    h3: {
      fontFamily: ['"Advent Pro"', '"Open Sans"', '"Helvetica Neue"']
    }
  }
});

export default responsiveFontSizes(theme)