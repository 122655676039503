import React from "react";
import { Grid, Typography } from "@material-ui/core";
import {
  VictoryChart,
  VictoryLabel,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
} from "victory";

class Skills extends React.Component {
  render() {
    return (
      <Grid container direction="row" wrap="wrap" spacing={4}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h2" paragraph>
            Skills &amp; Experience
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" align="left" gutterBottom>
            I have been a full-time UX developer since August 2017.
            <br />
            Since 2019, I have been responsible for all things UX in the product
            development team.
            <br />
            My tasks included:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1" align="left">
                Requirement gathering for new features
              </Typography>
            </li>
            <li>
              <Typography variant="body1" align="left">
                Building (paper) prototypes with InVision
              </Typography>
            </li>
            <li>
              <Typography variant="body1" align="left">
                Conducting user tests using InVision and Maze
              </Typography>
            </li>
            <li>
              <Typography variant="body1" align="left">
                Developing new features using React and Redux
              </Typography>
            </li>
            <li>
              <Typography variant="body1" align="left">
                Writing unit tests using Enzyme and Jest
              </Typography>
            </li>
          </ul>
          <Typography variant="body1" align="left" paragraph>
            I have also done a little freelance work while studying at the
            University of Applied Sciences (mostly portfolio websites built with
            Wordpress).
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h6" align="center">
            Coding
          </Typography>
          <VictoryChart
            theme={VictoryTheme.material}
            style={{ parent: { height: "360px" } }}
            domain={{ x: [0, 11], y: [0, 11] }}
            animate={{
              duration: 1500,
              onLoad: { duration: 250 },
            }}
            padding={{ top: 0, bottom: 0, left: 100, right: 0 }}
            horizontal
          >
            <VictoryAxis
              style={{
                axis: { stroke: "none" },
                axisLabel: { display: "none" },
                tickLabels: { display: "none" },
                grid: { stroke: "none" },
                ticks: { stroke: "none" },
              }}
            />
            <VictoryBar
              data={[
                { x: 10, y: 0, y0: 10, label: "HTML5" },
                { x: 9, y: 0, y0: 10, label: "CSS3 & SCSS" },
                { x: 8, y: 0, y0: 9, label: "JavaScript" },
                { x: 7, y: 0, y0: 6, label: "Typescript" },
                { x: 6, y: 0, y0: 9, label: "React" },
                { x: 5, y: 0, y0: 8, label: "Redux" },
                { x: 4, y: 0, y0: 6, label: "Jest & Enzyme" },
                { x: 3, y: 0, y0: 5, label: "Webpack" },
                { x: 2, y: 0, y0: 8, label: "Git" },
                { x: 1, y: 0, y0: 3, label: "Docker" },
              ]}
              barWidth={12}
              cornerRadius={{ bottom: 3 }}
              style={{ data: { fill: "#1F6FC6" } }}
              labelComponent={
                <VictoryLabel
                  capHeight={0.5}
                  dx={-16}
                  textAnchor="end"
                  verticalAnchor="middle"
                  style={{
                    fill: "rgba(0, 0, 0, 0.87)",
                    fontFamily: '"Open Sans", "Helvetica Neue", sans-serif',
                  }}
                />
              }
            ></VictoryBar>
          </VictoryChart>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h6" align="center">
            UX Design
          </Typography>
          <VictoryChart
            theme={VictoryTheme.material}
            style={{ parent: { height: "360px" } }}
            domain={{ x: [0, 11], y: [0, 11] }}
            animate={{
              duration: 1500,
              onLoad: { duration: 250 },
            }}
            padding={{ top: 0, bottom: 0, left: 100, right: 0 }}
            horizontal
          >
            <VictoryAxis
              style={{
                axis: { stroke: "none" },
                axisLabel: { display: "none" },
                tickLabels: { display: "none" },
                grid: { stroke: "none" },
                ticks: { stroke: "none" },
              }}
            />
            <VictoryBar
              data={[
                { x: 10, y: 0, y0: 10, label: "InVision" },
                { x: 9, y: 0, y0: 10, label: "Maze" },
                { x: 8, y: 0, y0: 6, label: "Sketch" },
                { x: 7, y: 0, y0: 5, label: "Frontify" },
                { x: 6, y: 0, y0: 1, label: "Photoshop" },
              ]}
              barWidth={12}
              cornerRadius={{ bottom: 3 }}
              style={{ data: { fill: "#1F6FC6" } }}
              labelComponent={
                <VictoryLabel
                  capHeight={0.5}
                  dx={-16}
                  textAnchor="end"
                  verticalAnchor="middle"
                  style={{
                    fill: "rgba(0, 0, 0, 0.87)",
                    fontFamily: '"Open Sans", "Helvetica Neue", sans-serif',
                  }}
                />
              }
            ></VictoryBar>
          </VictoryChart>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography component="h2" variant="h6" align="center">
            Languages
          </Typography>
          <VictoryChart
            theme={VictoryTheme.material}
            style={{ parent: { height: "360px" } }}
            domain={{ x: [0, 11], y: [0, 11] }}
            animate={{
              duration: 1500,
              onLoad: { duration: 250 },
            }}
            padding={{ top: 0, bottom: 0, left: 100, right: 0 }}
            horizontal
          >
            <VictoryAxis
              style={{
                axis: { stroke: "none" },
                axisLabel: { display: "none" },
                tickLabels: { display: "none" },
                grid: { stroke: "none" },
                ticks: { stroke: "none" },
              }}
            />
            <VictoryBar
              data={[
                { x: 10, y: 0, y0: 10, label: "German" },
                { x: 9, y: 0, y0: 10, label: "English" },
                { x: 8, y: 0, y0: 7, label: "Czech" },
                { x: 7, y: 0, y0: 7, label: "Turkish" },
                { x: 6, y: 0, y0: 5, label: "French" },
                { x: 5, y: 0, y0: 4, label: "Russian" },
                { x: 4, y: 0, y0: 2, label: "Spanish" },
              ]}
              barWidth={12}
              cornerRadius={{ bottom: 3 }}
              style={{ data: { fill: "#1F6FC6" } }}
              labelComponent={
                <VictoryLabel
                  capHeight={0.5}
                  dx={-16}
                  textAnchor="end"
                  verticalAnchor="middle"
                  style={{
                    fill: "rgba(0, 0, 0, 0.87)",
                    fontFamily: '"Open Sans", "Helvetica Neue", sans-serif',
                  }}
                />
              }
            ></VictoryBar>
          </VictoryChart>
        </Grid>
      </Grid>
    );
  }
}
export const Component = Skills;
export default Skills;
