import React from 'react'
import { Grid, Typography } from '@material-ui/core'

class Imprint extends React.Component {
  render() {
    return (
      <Grid container direction='column' spacing={4}>
        <Grid item>
          <Typography component='h1' variant='h2'>Impressum</Typography>
        </Grid>

        <Grid item>
          <p>Informationspflicht laut §5 E-Commerce Gesetz, §14 Unternehmensgesetzbuch, §63
            Gewerbeordnung und Offenlegungspflicht laut §25 Mediengesetz.</p>
          <p>
            <p>Mag. Lisa Maria Hadinger, BSc</p>
            <p>Weyringergasse 37/4/16, <br />1040 Wien, <br />Österreich</p>
            <p>
              <strong>E-Mail:</strong> <a href="mailto:hello@lisahadinger.at">hello@lisahadinger.at</a>
            </p>
            <p>Quelle: Erstellt mit dem <a title="Impressum Generator von firmenwebseiten.at"
                href="https://www.firmenwebseiten.at/impressum-generator/" target="_blank" rel="follow">Impressum
                Generator</a> von firmenwebseiten.at in Kooperation mit <a href="https://www.pressefeuer.at" target="_blank"
                rel="follow" title="">pressefeuer.at</a>
            </p>
            <h2>EU-Streitschlichtung</h2>
            <p>Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie
              über die Online-Streitbeilegungsplattform (OS-Plattform) informieren.<br />
              Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der Europäischen
              Kommission unter <a  
                href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE" target="_blank"
                rel="nofollow">http://ec.europa.eu/odr?tid=221141270</a> zu richten. Die dafür notwendigen Kontaktdaten finden
              Sie oberhalb in unserem Impressum.</p>
            <p>Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder verpflichtet sind, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            <h2>Haftung für Inhalte dieser Webseite</h2>
            <p>Wir entwickeln die Inhalte dieser Webseite ständig weiter und bemühen uns korrekte und aktuelle Informationen
              bereitzustellen. Leider können wir keine Haftung für die Korrektheit aller Inhalte auf dieser Webseite
              übernehmen, speziell für jene die seitens Dritter bereitgestellt wurden.</p>
            <p>Sollten Ihnen problematische oder rechtswidrige Inhalte auffallen, bitten wir Sie uns umgehend zu kontaktieren,
              Sie finden die Kontaktdaten im Impressum.</p>
            <h2>Haftung für Links auf dieser Webseite</h2>
            <p>Unsere Webseite enthält Links zu anderen Webseiten für deren Inhalt wir nicht verantwortlich sind. Haftung für
              verlinkte Websites besteht laut <a  
                href="https://www.ris.bka.gv.at/Dokument.wxe?Abfrage=Bundesnormen&Dokumentnummer=NOR40025813&tid=221141270"
                target="_blank" rel="noopener nofollow">§ 17 ECG</a> für uns nicht, da wir keine Kenntnis rechtswidriger
              Tätigkeiten hatten und haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind und wir Links
              sofort entfernen würden, wenn uns Rechtswidrigkeiten bekannt werden.</p>
            <p>Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitten wir Sie uns zu kontaktieren, Sie finden
              die Kontaktdaten im Impressum.</p>
            <h2>Urheberrechtshinweis</h2>
            <p>Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen dem Urheberrecht. Falls notwendig,
              werden wir die unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich verfolgen.</p>
            <h2>Bildernachweis</h2>
            <p>Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich geschützt.</p>
            <p>Die Bilderrechte liegen bei den folgenden Fotografen und Unternehmen:</p>
            <ul>
              <li>Lisa Maria Hadinger</li>
            </ul>
            <h1>Datenschutzerklärung</h1>
            <h2>Datenschutz</h2>
            <p>Wir haben diese Datenschutzerklärung (Fassung 07.02.2021-221141270) verfasst, um Ihnen gemäß der Vorgaben der
              <a  
                href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=221141270" target="_blank"
                rel="noopener noreferrer nofollow">Datenschutz-Grundverordnung (EU) 2016/679</a> zu erklären, welche
              Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser
              Webseite haben.</p>
            <p>Datenschutzerklärungen klingen für gewöhnlich sehr technisch. Diese Version soll Ihnen hingegen die wichtigsten
              Dinge so einfach und klar wie möglich beschreiben. Soweit es möglich ist, werden technische Begriffe
              leserfreundlich erklärt. Außerdem möchten wir vermitteln, dass wir mit dieser Website nur dann Informationen
              sammeln und verwenden, wenn eine entsprechende gesetzliche Grundlage gegeben ist. Das ist sicher nicht möglich,
              wenn man möglichst knappe, technische Erklärungen abgibt, so wie sie im Internet oft Standard sind, wenn es um
              Datenschutz geht. Ich hoffe Sie finden die folgenden Erläuterungen interessant und informativ und vielleicht ist
              die eine oder andere Information dabei, die Sie noch nicht kannten.<br />
              Wenn trotzdem Fragen bleiben, möchten wir Sie bitten den vorhandenen Links zu folgen und sich weitere
              Informationen auf Drittseiten anzusehen, oder uns einfach eine E-Mail zu schreiben. Unsere Kontaktdaten finden
              Sie im Impressum.</p>
            <h2>Automatische Datenspeicherung</h2>
            <p>Wenn Sie heutzutage Websites besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so
              auch auf dieser Website. Diese gesammelten Daten sollten möglichst sparsam und nur mit Begründung gesammelt
              werden werden. Mit Website meinen wir übrigens die Gesamtheit aller Webseiten auf Ihrer Domain, d.h. alles von
              der Startseite (Homepage) bis hin zur aller letzten Unterseite (wie dieser hier). Mit Domain meinen wir zum
              Beispiel beispiel.de oder musterbeispiel.com.</p>
            <p>Auch während Sie unsere Website jetzt gerade besuchen, speichert unser Webserver – das ist der Computer auf dem
              diese Webseite gespeichert ist –  aus Gründen der Betriebssicherheit, zur Erstellung von Zugriffsstatistik usw.
              in der Regel automatisch Daten wie</p>
            <ul>
              <li>die komplette Internetadresse (URL) der aufgerufenen Webseite (z. B.
                https://www.beispielwebsite.de/beispielunterseite.html/)</li>
              <li>Browser und Browserversion (z. B. Chrome 87)</li>
              <li>das verwendete Betriebssystem (z. B. Windows 10)</li>
              <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL) (z. B.
                https://www.beispielquellsite.de/vondabinichgekommen.html/)</li>
              <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird
                (z. B. COMPUTERNAME und 194.23.43.121)</li>
              <li>Datum und Uhrzeit</li>
              <li>in Dateien, den sogenannten Webserver-Logfiles.</li>
            </ul>
            <p>Zur Veranschaulichung:</p>
            <p>
              <img role="img" src="https://www.adsimple.at/wp-content/uploads/2018/03/browser-und-webserver-211141072.svg"
                width="962" height="234" />
            </p>
            <p>In der Regel werden diese Dateien zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese
              Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem
              Verhalten von Behörden eingesehen werden.</p>
            <p>Kurz gesagt: Ihr Besuch wird durch unseren Provider (Firma, die unsere Website auf speziellen Computern
              (Servern) laufen lässt), protokolliert, aber wir geben Ihre Daten nicht weiter!</p>
            <h2>Rechte laut Datenschutzgrundverordnung</h2>
            <p>Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen <a  
                href="https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10001597&tid=221141270"
                target="_blank" rel="noopener noreferrer nofollow">Datenschutzgesetzes (DSG)</a> grundsätzlich die folgende
              Rechte zu:</p>
            <ul>
              <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
              <li>Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)</li>
              <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
              <li>Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der
                Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
              </li>
              <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
              <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
              <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung —
                einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)</li>
            </ul>
            <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
              datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der
              Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie unter <a
                href="https://www.dsb.gv.at/?tid=221141270" rel="nofollow">https://www.dsb.gv.at/</a> finden.</p>
            <h2>TLS-Verschlüsselung mit https</h2>
            <p>TLS, Verschlüsselung und https klingen sehr technisch und sind es auch. Wir verwenden HTTPS (das Hypertext
              Transfer Protocol Secure steht für „sicheres Hypertext-Übertragungsprotokoll“) um Daten abhörsicher im Internet
              zu übertragen.<br />
              Das bedeutet, dass die komplette Übertragung aller Daten von Ihrem Browser zu unserem Webserver abgesichert ist
              – niemand kann „mithören“.</p>
            <p>Damit haben wir eine zusätzliche Sicherheitsschicht eingeführt und erfüllen Datenschutz durch Technikgestaltung
              <a  
                href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=221141270"
                target="_blank" rel="noopener noreferrer nofollow">Artikel 25 Absatz 1 DSGVO</a>). Durch den Einsatz von TLS
              (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir
              den Schutz vertraulicher Daten sicherstellen.<br />
              Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol <img role="img"
                src="https://www.adsimple.at/wp-content/uploads/2018/03/schlosssymbol-https-211141072.svg" width="17"
                height="18" /> links oben im Browser links von der Internetadresse (z. B. beispielseite.de) und der Verwendung
              des Schemas https (anstatt http) als Teil unserer Internetadresse.<br />
              Wenn Sie mehr zum Thema Verschlüsselung wissen möchten, empfehlen wir die Google Suche nach „Hypertext Transfer
              Protocol Secure wiki“ um gute Links zu weiterführenden Informationen zu erhalten.</p>
            <h2>Google Fonts Datenschutzerklärung</h2>
            <p>Auf unserer Website verwenden wir Google Fonts. Das sind die „Google-Schriften“ der Firma Google Inc. Für den
              europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für
              alle Google-Dienste verantwortlich.</p>
            <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
              Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden
              über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen
              nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben,
              brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google
              übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten
              und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail
              ansehen.</p>
            <h3  >Was sind Google Fonts?</h3>
            <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a
                  href="https://de.wikipedia.org/wiki/Google_LLC?tid=221141270"
                rel="nofollow">Google</a> Ihren Nutzern kostenlos zu Verfügung stellen.</p>
            <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter
              der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
            <h3  >Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
            <p>Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen
              Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle
              Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die
              Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige
              Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche
              Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu
              Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen
              Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts
              unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert
              zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone,
              iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und
              einheitlich wie möglich darstellen können.</p>
            <h3  >Welche Daten werden von Google gespeichert?</h3>
            <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
              externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
              IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
              Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig
              ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im
              Softwarebereich.</p>
            <p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die
              gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
              veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google
              auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten
              werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das
              Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
            <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen
              wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des
              Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht
              klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
            <h3  >Wie lange und wo werden die Daten gespeichert?</h3>
            <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU
              angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein
              Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer
              Webseite ändern kann.</p>
            <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
              Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden
              sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten
              Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung
              von Sprache zu erhöhen und das Design zu verbessern.</p>
            <h3  >Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
            <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten
              werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen
              Sie den Google-Support auf <a  
                href="https://support.google.com/?hl=de&tid=221141270"
                rel="nofollow">https://support.google.com/?hl=de&tid=221141270</a> kontaktieren. Datenspeicherung verhindern
              Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
            <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können
              also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr
              zu Google Fonts und weiteren Fragen finden Sie auf <a  
                href="https://developers.google.com/fonts/faq?tid=221141270"
                rel="nofollow">https://developers.google.com/fonts/faq?tid=221141270</a>. Dort geht zwar Google auf
              datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind
              nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu
              bekommen.</p>
            <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch
              auf <a   href="https://policies.google.com/privacy?hl=de&tid=221141270"
                rel="nofollow">https://www.google.com/intl/de/policies/privacy/</a> nachlesen.</p>
            <h2>jQuery CDN Datenschutzerklärung</h2>
            <p>Um Ihnen unsere Website bzw. all unsere einzelnen Unterseiten (Webseiten) auf unterschiedlichen Geräten schnell
              und problemlos auszuliefern, nutzen wir Dienste von jQuery CDN des Unternehmens jQuery Foundation. jQuery wird
              über das Content Delivery Network (CDN) des amerikanischen Software-Unternehmens StackPath (LCC 2012 McKinney
              Ave. Suite 1100, Dallas, TX 75201, USA) verteilt. Durch diesen Dienst werden personenbezogene Daten von Ihnen
              gespeichert, verwaltet und verarbeitet.</p>
            <p>Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet
              miteinander verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen
              Lastspitzen schnell ausgeliefert werden.</p>
            <p>jQuery nutzt JavaScript-Bibliotheken, um unsere Website-Inhalte zügig ausliefern zu können. Dafür lädt ein
              CDN-Server die nötigen Dateien. Sobald eine Verbindung zum CDN-Server aufgebaut ist, wird Ihre IP-Adresse
              erfasst und gespeichert. Das geschieht nur, wenn diese Daten nicht schon durch einen vergangenen Websitebesuch
              in Ihrem Browser gespeichert sind.</p>
            <p>In den Datenschutz-Richtlinien von StackPath wird ausdrücklich erwähnt, dass StackPath aggregierte und
              anonymisierte Daten von diversen Diensten (wie eben auch jQuery) für die Erweiterung der Sicherheit und für
              eigene Dienste benutzen. Diese Daten können Sie als Person allerdings nicht identifizieren.</p>
            <p>Wenn Sie nicht wollen, dass es zu dieser Datenübertragung kommt, haben Sie immer auch die Möglichkeit
              Java-Scriptblocker wie beispielsweise <a href="https://www.ghostery.com/de/"
                target="_blank" rel="follow noopener noreferrer">ghostery.com</a> oder <a  
                href="https://noscript.net/" target="_blank" rel="follow noopener noreferrer">noscript.net</a> zu
              installieren. Sie können aber auch einfach in Ihrem Browser die Ausführung von JavaScript-Codes deaktivieren.
              Wenn Sie sich für die Deaktivierung von JavaScript-Codes entscheiden, verändern sich auch die gewohnten
              Funktionen. So wird beispielsweise eine Website nicht mehr so schnell geladen.</p>
            <p>StackPath ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
              Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a 
                href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active" target="_blank"
                rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active</a>.<br />
              Mehr Informationen zum Datenschutz bei StackPath finden Sie unter <a  
                href="https://www.stackpath.com/legal/privacy-statement/?tid=221141270" target="_blank"
                rel="noopener noreferrer nofollow">https://www.stackpath.com/legal/privacy-statement/</a> und zu jQuery unter
              <a href="https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf"
                target="_blank"
                rel="follow noopener noreferrer">https://openjsf.org/wp-content/uploads/sites/84/2019/11/OpenJS-Foundation-Privacy-Policy-2019-11-15.pdf</a>.
            </p>
            <h2>BootstrapCDN Datenschutzerklärung</h2>
            <p>Um Ihnen all unsere einzelnen Webseiten (Unterseiten unserer Website) auf allen Geräten schnell und sicher
              ausliefern zu können, nutzen wir das Content Delivery Network (CDN) BootstrapCDN des amerikanischen
              Software-Unternehmens StackPath, LLC 2012 McKinney Ave. Suite 1100, Dallas, TX 75201, USA.</p>
            <p>Ein Content Delivery Network (CDN) ist ein Netzwerk regional verteilter Server, die über das Internet
              miteinander verbunden sind. Durch dieses Netzwerk können Inhalte, speziell sehr große Dateien, auch bei großen
              Lastspitzen schnell ausgeliefert werden.</p>
            <p>BootstrapCDN funktioniert so, dass sogenannte JavaScript-Bibliotheken an Ihren Browser ausgeliefert werden.
              Lädt nun Ihr Browser eine Datei vom BootstrapCDN herunter, wird Ihre IP-Adresse während der Verbindung zum
              Bootstrap-CDN-Server an das Unternehmen StockPath übermittelt.</p>
            <p>StackPath erwähnt auch in der hauseigenen Datenschutzerklärung, dass das Unternehmen aggregierte und
              anonymisierte Daten von diversen Diensten (wie BootstrapCDN) für die Erweiterung der Sicherung und für andere
              StackPath-Dienste und Clients verwenden. All diese Daten können aber keine Person identifizieren.</p>
            <p>StackPath ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere
              Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf <a
                href="https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active" target="_blank"
                rel="follow noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000CbahAAC&status=Active</a>.<br />
              Mehr Informationen zum Datenschutz bei StackPath bzw. BootstrapCDN finden Sie auf <a
                href="https://www.bootstrapcdn.com/privacy-policy/?tid=221141270"
                target="_blank" rel="noopener noreferrer nofollow">https://www.bootstrapcdn.com/privacy-policy/</a>.</p>
            <p>Quelle: Erstellt mit dem <a title="Datenschutz Generator von firmenwebseiten.at"
                href="https://www.firmenwebseiten.at/datenschutz-generator/" target="_blank" rel="follow">Datenschutz
                Generator</a> von firmenwebseiten.at in Kooperation mit <a href="https://www.meinhaushalt.at" target="_blank"
                rel="follow" title="">meinhaushalt.at</a>
            </p>
          </p>
        </Grid>
      </Grid>
    )
  }
}

export const Component = Imprint
export default Imprint