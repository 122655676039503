import React from 'react'
import { Grid, Typography } from '@material-ui/core'

class About extends React.Component {
  render() {
    return (
      <Grid container direction='column' spacing={4}>
        <Grid item>
          <Typography component='h1' variant='h2'>About Me</Typography>
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={4}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
              <img className='thumbnail' src='./static-assets/portrait_lisa-hadinger.jpg' alt='Portrait Lisa Hadinger'/>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={9} xl={9}>
              <Typography variant='body1' align='left' paragraph>My name is <b>Lisa Hadinger</b>; I was born and raised in Upper Austria and have been living in Vienna since 2007.</Typography>
              <Typography variant='body1' align='left' paragraph>
              I graduated from the <b>University of Vienna</b> in 2012 and started working in the field of adult education.<br/>Additionally, I started to teach myself how to code and soon decided to focus on programming in my further career.
              </Typography>
              <Typography variant='body1' align='left' paragraph>
              In 2014 I enrolled at the <b>University of Applied Sciences St. Pölten / Austria</b> for a bachelor's degree in media technology.<br/>
              Since graduating in 2017 I have been working in the e-learning industry and helped develop and improve a <b>web app for creating interactive web-based trainings</b>.<br/>I am also very <b>passionate about UX</b> and try to make the things I build simple and enjoyable to use! 
              </Typography>
              <Typography variant='body1' align='left'>
              Apart from web development and UX, my interests include linguistics, psychology, neurology and photography, and I LOVE travelling and spending time in the mountains!
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        
      </Grid>
    )
  }
}

export const Component = About
export default About
