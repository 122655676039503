import React, { useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles } from "@material-ui/core/styles";
import {
  Container,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  SvgIcon,
  Menu,
  MenuItem,
  Theme,
  createStyles,
  WithStyles,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";
import About from "./views/About";
import Skills from "./views/Skills";
import Projects from "./views/Projects";
import Photography from "./views/Photography";
import Imprint from "./views/Imprint";

import "./App.css";

const styles = (theme: Theme) =>
  createStyles({
    logo: {
      width: theme.spacing(3.5),
      borderRadius: 4,
      paddingTop: 2,
    },
    mobileNavLink: {
      textDecoration: "none",
    },
  });

interface Props extends WithStyles<typeof styles> {}

function App(props: Props) {
  const classes = props.classes;
  let theme = useTheme();
  let [menuOpen, handleMenuOpen] = useState<boolean>(false);

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item>
                  <img
                    className={classes.logo}
                    src="./static-assets/logo.png"
                    alt="Logo"
                  />
                </Grid>
                <Grid item>
                  <NavLink to="/" exact={true}>
                    <Typography style={{ color: "#fff" }} variant="body1">
                      Lisa Hadinger
                    </Typography>
                  </NavLink>
                </Grid>
              </Grid>
            </Grid>

            {useMediaQuery(theme.breakpoints.up("md")) ? (
              <Grid item>
                <Grid container direction="row" spacing={4}>
                  <Grid item>
                    <NavLink
                      to="/about"
                      className="nav-link"
                      activeClassName="selected"
                    >
                      <Typography variant="button">About Me</Typography>
                    </NavLink>
                  </Grid>
                  <Grid item>
                    <NavLink
                      to="/skills"
                      className="nav-link"
                      activeClassName="selected"
                    >
                      <Typography variant="button">
                        Skills &amp; Experience
                      </Typography>
                    </NavLink>
                  </Grid>
                  <Grid item>
                    <NavLink
                      to="/projects"
                      className="nav-link"
                      activeClassName="selected"
                    >
                      <Typography variant="button">
                        Papers &amp; Side Projects
                      </Typography>
                    </NavLink>
                  </Grid>
                  <Grid item>
                    <NavLink
                      to="/photography"
                      className="nav-link"
                      activeClassName="selected"
                    >
                      <Typography variant="button">Photography</Typography>
                    </NavLink>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item>
                <IconButton
                  id="mobileMenuButton"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={(evt) => handleMenuOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="mobileMenu"
                  anchorEl={document.querySelector("#mobileMenuButton")}
                  keepMounted
                  open={menuOpen}
                  onClose={() => handleMenuOpen(false)}
                >
                  <MenuItem onClick={() => handleMenuOpen(false)}>
                    <NavLink className={classes.mobileNavLink} to="/about">
                      About me
                    </NavLink>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuOpen(false)}>
                    <NavLink className={classes.mobileNavLink} to="/skills">
                      Skills &amp; Experience
                    </NavLink>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuOpen(false)}>
                    <NavLink className={classes.mobileNavLink} to="/projects">
                      Papers &amp; Side Projects
                    </NavLink>
                  </MenuItem>
                  <MenuItem onClick={() => handleMenuOpen(false)}>
                    <NavLink
                      className={classes.mobileNavLink}
                      to="/photography"
                    >
                      Photography
                    </NavLink>
                  </MenuItem>
                </Menu>
              </Grid>
            )}
          </Grid>
        </Toolbar>
      </AppBar>

      <Container maxWidth="md" className="content">
        <Switch>
          {/* <Redirect exact from='/' to='/about' /> */}
          <Route exact path="/">
            <About />
          </Route>
          <Route path="/skills">
            <Skills />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/photography">
            <Photography />
          </Route>
          <Route path="/imprint">
            <Imprint />
          </Route>
        </Switch>
      </Container>

      <footer>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <a
                  href="https://at.linkedin.com/pub/lisa-hadinger/69/137/841"
                  target="_blank"
                  rel="noreferrer"
                  title="My LinkedIn profile"
                >
                  <SvgIcon
                    className="footer-icon"
                    style={{ fontSize: "1rem" }}
                    htmlColor="rgba(0, 0, 0, 0.54)"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </SvgIcon>
                </a>
              </Grid>
              <Grid item>
                <a
                  href="https://www.xing.com/profile/Lisa_Hadinger"
                  target="_blank"
                  rel="noreferrer"
                  title="My Xing profile"
                >
                  <SvgIcon
                    className="footer-icon"
                    style={{ fontSize: "1rem" }}
                    htmlColor="rgba(0, 0, 0, 0.54)"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="xing"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 384 512"
                    >
                      <path
                        fill="currentColor"
                        d="M162.7 210c-1.8 3.3-25.2 44.4-70.1 123.5-4.9 8.3-10.8 12.5-17.7 12.5H9.8c-7.7 0-12.1-7.5-8.5-14.4l69-121.3c.2 0 .2-.1 0-.3l-43.9-75.6c-4.3-7.8.3-14.1 8.5-14.1H100c7.3 0 13.3 4.1 18 12.2l44.7 77.5zM382.6 46.1l-144 253v.3L330.2 466c3.9 7.1.2 14.1-8.5 14.1h-65.2c-7.6 0-13.6-4-18-12.2l-92.4-168.5c3.3-5.8 51.5-90.8 144.8-255.2 4.6-8.1 10.4-12.2 17.5-12.2h65.7c8 0 12.3 6.7 8.5 14.1z"
                      ></path>
                    </svg>
                  </SvgIcon>
                </a>
              </Grid>
              <Grid item>
                <a
                  href="mailto:hello@lisahadinger.at"
                  title="Send me an e-mail!"
                >
                  <SvgIcon
                    className="footer-icon"
                    style={{ fontSize: "1rem" }}
                    htmlColor="rgba(0, 0, 0, 0.54)"
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="envelope"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                      ></path>
                    </svg>
                  </SvgIcon>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <NavLink to="/imprint">
              <Typography
                className="footer-link"
                variant="body2"
                color="textSecondary"
              >
                © 2021 Lisa Hadinger
              </Typography>
            </NavLink>
          </Grid>
        </Grid>
      </footer>
    </div>
  );
}

export default withStyles(styles)(App);
